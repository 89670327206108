<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">{{ lang.label }} Section</h5>
      <div class="row">
        <div class="col-md-12 col-sm-12">
          <ValidationProvider
            vid="en.question"
            rules="required"
            name="The Question"
            v-slot="{ passed, failed,errors }">
            <fg-input type="text"
                      :error="failed ? errors[0]: null"
                      label="Question"
                      name="question"
                      fou
                      v-model="formData.question">
            </fg-input>
          </ValidationProvider>
          <div class="row">
            <div class="col-md-12">
              <label>Answers List</label>
              <div class="col-12">
                <div :class="{'scrollable': isScrollable}">
                  <template class="row" v-for="(listItem,listItemIndex) in formData.answers_list">
                    <div class="row team_list_items">
                      <label>Item # {{ listItemIndex + 1 }}</label>
                      <!--                list item title-->
                      <div class="col-md-12">
                        <ValidationProvider
                          vid="en.answers_list"
                          rules=""
                          name="The Title"
                          v-slot="{ passed, failed,errors }">
                          <fg-input type="text"
                                    :key="'title_'+listItemIndex"
                                    :error="failed ? errors[0]: null"
                                    :label="'Title '+(listItemIndex+1)"
                                    v-model="formData.answers_list[listItemIndex]">
                          </fg-input>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-sm-1 align-self-center mt-3">
                      <l-button
                        @click="removeItem(formData.answers_list, listItemIndex)"
                        type="danger" size="sm">
                        <i class="fa fa-trash"></i>
                      </l-button>
                    </div>
                  </template>
                </div>

                <l-button
                  class="mt-3"
                  type="success"
                  @click="addNewItem(formData.answers_list, '')"
                  size="sm">
                  <i class="fa fa-plus"></i>
                </l-button>
              </div>
              <button style="margin-right: 20px;" @click="generateCountries($event)" class="btn btn-default btn-fill btn-wd btn-back">Generate all countries</button>
              <button @click="clearAllAnswerData($event)" class="btn btn-danger btn-fill btn-wd btn-back">Clear all Answer data</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { Select, Option } from 'element-ui'
  import { extend } from "vee-validate";
  import { required, email, regex } from "vee-validate/dist/rules";
  import Editor from '@tinymce/tinymce-vue'
  import FormGroupTextArea from "@/components";

  extend("required", required);
  extend("email", email);
  extend("regex", regex);

  export default {
    components: {
      FormGroupTextArea,
      [Select.name]: Select,
      [Option.name]: Option,
      'editor': Editor
    },
    props: {
      lang: {
        type: Object
      },
      editMode: {
        type: Boolean,
        default: false
      },
      isSubmit: {
        type: Boolean,
        default: false
      },
      formData:{
        type: Object,
        default() {
          return {
            question: "",
            answers_list: [],
          }
        }
      },
    },
    computed: {
      isScrollable() {
        return this.formData.answers_list.length > 10;
      }
    },
    data () {
      return {
        editorConfig: this.getEditorConfig(),
        editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate().then(res => {
          if (res){
            this.$emit('submit-step', this.formData, this.lang.value, this.isSubmit)
          }else{
            this.$emit('on-validated', res)
          }
          return res
        });
      },
      addNewItem(listItems, initObject) {
        listItems.push(initObject);
      },
      removeItem(listItems, index) {
        listItems.splice(index, 1);
      },
      generateCountries(event){
        this.loader = this.$loading.show({
          container: this.$refs.templateForm
        });
        event.preventDefault();
        this.axios.get('https://restcountries.com/v2/all')
          .then(response => {
            this.formData.answers_list = response.data.map(country => country.translations.fr);
            this.loader.hide();
          })
          .catch(error => {
            console.error(error);
            this.loader.hide();
            this.$notify({
              message: "Failed to add all countries in answers list",
              timeout: 2000,
              type: 'warning'
            });
          });
      },
      clearAllAnswerData(event){
        event.preventDefault();
        this.formData.answers_list = [];
      }
    }
  }
</script>
<style>
</style>
